import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Header/Navbar';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/ScrolltoTop';
import whatsapp from './whatsapp.png'; // Ensure this path is correct
import { BASE_URL } from './config';

// Lazy-loaded components
const Home = lazy(() => import('./components/Main/Home'));
const TreatmentsSection = lazy(() => import('./components/Main/TreatmentsSection'));
const MainTreatment = lazy(() => import('./components/Main/MainTreatment'));
const ContactSection = lazy(() => import('./components/Main/ContectSection'));
const Rules = lazy(() => import('./components/Rules'));
const Plans = lazy(() => import('./components/Main/PlansPage'))
const Abouts = lazy(() => import('./components/Abouts'));
const NotFound = lazy(() => import('./components/NotFound'));
const BlogSection = lazy(() => import('./components/Main/BlogSection'))
const Blog = lazy(() => import('./components/Blog')) // NotFound component

// Layout component
const Layout = ({ children }) => (
  <>
    <Navbar />
    <a href="https://wa.me/9772398555" className='whatsapp-float'>
      <img src={whatsapp} alt="WhatsApp" />
    </a>
    {children}
    <Footer />
  </>
);

// AppRouter component
const AppRouter = ({ routes }) => {
  const [loadedRoutes, setLoadedRoutes] = useState(routes || []);

  useEffect(() => {
    if (!routes) {
      // Fetch routes from the backend if not provided as props
      fetch(BASE_URL + '/api/v1/routes/fetchRoutes')
        .then(response => response.json())
        .then(data => {
          setLoadedRoutes(data.data);
        })
        .catch(error => {
          console.error('Error fetching routes:', error);
        });
    }
  }, [routes]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Layout>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />

          {loadedRoutes && loadedRoutes.map((route, index) => {
            let Component;

            switch (route.component) {
              case 'Plans':
                Component = Plans
                break
              case 'TreatmentsSection':
                Component = TreatmentsSection;
                break;
              case 'MainTreatment':
                Component = MainTreatment;
                break;
              case 'ContactSection':
                Component = ContactSection;
                break;
              case 'Rules':
                Component = Rules;
                break;
              case 'Abouts':
                Component = Abouts;
                break;
              case 'BlogSection':
                Component = BlogSection;
                break;
              case 'Blog':
                Component = Blog;
                break;
              default:
                Component = NotFound;
            }

            return (
              <Route
                key={index}
                path={route.path}
                element={
                  route.path == "/contactus" ?
                    <div style={{ marginTop: '5%' }}>

                      <Component
                        id={route.id}
                        title={route.title}
                        description={route.description}
                        canonicalUrl={route.canonical}
                      />
                    </div> :


                    <Component
                      id={route.id}
                      title={route.title}
                      description={route.description}
                      default_image={route.default_image}
                      default_text={route.default_text}

                      canonicalUrl={route.canonical}
                    />

                }
              />
            );
          })}

          {/* Fallback route: Render NotFound component if no route matches */}

        </Routes>
      </Layout>
    </Suspense>
  );
};

export default AppRouter;